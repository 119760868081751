var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.priority)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.tooltipText,
    delay: { show: 1500, hide: 0 },
    hideOnClick: true,
  }),expression:"{\n    content: tooltipText,\n    delay: { show: 1500, hide: 0 },\n    hideOnClick: true,\n  }"}],staticClass:"shrink-0 rounded-sm inline-flex w-3.5 h-3.5",class:{
    'bg-red-50 dark:bg-red-700 dark:bg-opacity-30 text-red-500 dark:text-red-600': _vm.isUrgent,
    'bg-slate-50 dark:bg-slate-700 text-slate-600 dark:text-slate-200': !_vm.isUrgent,
  }},[_c('fluent-icon',{attrs:{"icon":("priority-" + (_vm.priority.toLowerCase())),"size":"14","view-box":"0 0 14 14"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }